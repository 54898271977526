import React from "react";
import "./Button.less";

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  children: React.ReactNode;
  onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  variant?: "primary" | "secondary";
  size?: "small";
  className?: string;
  disabled?: boolean;
  isOnGround?: boolean;
}

const Button = (props: Props) => {
  const classNames = ["button", props.className];
  switch (props.variant) {
    default:
    case "primary":
      classNames.push("button--primary");
      break;
    case "secondary":
      classNames.push("button--secondary");
      break;
  }

  if (props.size === "small") {
    classNames.push("button--small");
  }

  if (props.isOnGround) {
    classNames.push("button--on-ground");
  }

  let optionalProps: React.ButtonHTMLAttributes<HTMLButtonElement> = {
    disabled: undefined,
  };
  if (props.disabled) {
    optionalProps.disabled = true;
  }

  return (
    <button
      onClick={props.onClick}
      className={classNames.join(" ")}
      {...optionalProps}
    >
      {props.children}
    </button>
  );
};

export default Button;
