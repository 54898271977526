import React from "react";
import { PageLocale, SiteTranslations } from "../templates/Page/types";

type Translation = {
  const: string;
  value: string;
};

const defaultState = {
  t: (key: string) => key,
};

const TranslationContext = React.createContext(defaultState);

interface Props {
  children?: React.ReactNode;
  locale: PageLocale;
  translations: SiteTranslations;
}

const TranslationProvider = (props: Props) => {
  const translations: Translation[] = props.translations.edges.map(
    (translation) => {
      let value = translation.node.default;
      const valueFromList = translation.node.list.find(
        (x) => x.locale.locale.current === props.locale
      );
      if (valueFromList) {
        value = valueFromList.value;
      }
      return {
        const: translation.node.const,
        value,
      };
    }
  );

  const t = (key: string) => {
    const translation = translations.find((x) => x.const === key);
    return translation ? translation.value : `[${key}]`;
  };

  return (
    <TranslationContext.Provider
      value={{
        t,
      }}
    >
      {props.children}
    </TranslationContext.Provider>
  );
};
export default TranslationContext;
export { TranslationProvider };
