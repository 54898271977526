import React from "react";

interface Props {
  email: string;
  className?: string;
}

const ObfuscatedLink = (props: Props) => {
  const { email, ...rest } = props;
  const [isHuman, setIsHuman] = React.useState(false);

  const onInteract = () => setIsHuman(true);

  return (
    <a
      href={isHuman ? `mailto:${email}` : "#"}
      target="_blank"
      rel="norefferer noopener"
      {...rest}
      onMouseEnter={onInteract}
      onFocus={onInteract}
      onClick={onInteract}
    >
      {email.split("@").join("\u200B\u200B\u200B\u0040\u200B\u200B\u200B")}
    </a>
  );
};

export default ObfuscatedLink;
