import React from "react";
import "./Toggle.less";

interface Props {
  // onClick?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  className?: string;
  labels?: {
    left: string;
    right: string;
  };
  onChange?: (value: string | boolean) => void;
}

const Toggle = (props: Props) => {
  const classNames = ["toggle"];

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!props.onChange) return;
    if (props.labels) {
      props.onChange(
        event.target.checked ? props.labels.right : props.labels.left
      );
    }
    return event.target.checked;
  };

  return (
    <label className={classNames.join(" ")}>
      <input className="toggle__input" type="checkbox" onChange={onChange} />
      {props.labels?.left || ""}
      <div className="toggle__toggle" />
      {props.labels?.right || ""}
    </label>
  );
};

export default Toggle;
