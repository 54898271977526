import React from "react";
import PagePadding from "../../../components/templates/PagePadding/PagePadding";
import "./CmsFAQ.less";
import CmsFAQSection from "./CmsFAQSection";
import { ContentFAQ } from "./types";

interface Props {
  content: ContentFAQ;
}

const CmsFAQ = (props: Props) => {
  const content = props.content;
  return (
    <PagePadding className="cms-faq">
      <div className="cms-faq__head">
        <h2 className="cms-faq__headline">{content.title}</h2>
        {content.description && <div>{content.description}</div>}
      </div>
      <div className="cms-faq__sections">
        {content.sections.map((section, i) => (
          <CmsFAQSection key={i} section={section} className="cms-faq__section" />
        ))}
      </div>
    </PagePadding>
  );
};

export default CmsFAQ;
