import React from "react";
import CTA from "../../../components/atoms/CTA/CTA";
import Image from "../../../components/atoms/Image/Image";
import Richtext from "../../../components/atoms/Richtext/Richtext";
import { ContentStage } from "./types";
import "./CmsStage.less";

interface Props {
  content: ContentStage;
}

const CmsStage = (props: Props) => {
  const content = props.content;
  const classNames = ["cms-stage"];

  const cta = content.inlineCTA;
  const isExternal = Boolean(cta.isExternal && cta.href);
  const isCTAValid = Boolean(
    (isExternal && cta.href) || (!isExternal && cta.page?.url?.current)
  );

  return (
    <div className={classNames.join(" ")}>
      <Image
        image={content.asset}
        className="cms-stage__asset"
        objectFit="contain"
      />
      <div className="cms-stage__content">
        <span className="cms-stage__dateline">{content.dateline}</span>
        <h1 className="cms-stage__headline">{content.headline}</h1>
        <Richtext blocks={content._rawText} className="cms-stage__text" />
        {content.isCTAActive && isCTAValid && (
          <div className="cms-stage__cta">
            <CTA
              to={isExternal ? cta.href : cta.page.url.current}
              isExternal={isExternal}
            >
              {cta.cta}
            </CTA>
          </div>
        )}
      </div>
    </div>
  );
};

export default CmsStage;
