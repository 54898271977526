import React from "react";
import ToolAutomationUrlEncoder from "./tools/AutomationUrlEncoder/AutomationUrlEncoder";
import { ContentTool } from "./types";

interface Props {
  content: ContentTool;
}

const CmsTool = (props: Props) => {
  const content = props.content;

  switch (content.type) {
    case "automation-url-encoder":
      return <ToolAutomationUrlEncoder />;
    default:
      return null;
  }
};

export default CmsTool;
