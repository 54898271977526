import React from "react";
import Richtext from "../../../components/atoms/Richtext/Richtext";
import { RawRichtext } from "../../../components/atoms/Richtext/types";
import "./CmsFAQQuestion.less";

interface Props {
  question: string;
  answer: RawRichtext;
}

const CmsFAQQuestion = (props: Props) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const classNames = ["faq-question"];

  const classNamesAnswer = ["faq-question__answer"];
  if (isOpen) {
    classNamesAnswer.push("faq-question__answer--open");
  }

  const onToggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <li className={classNames.join(" ")}>
      <h4 className="faq-question__question" onClick={onToggle}>
        {props.question}
      </h4>
      <div className={classNamesAnswer.join(" ")}>
        <Richtext blocks={props.answer} />
      </div>
    </li>
  );
};

export default CmsFAQQuestion;
