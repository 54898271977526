import { Link } from "gatsby";
import React from "react";
import Image from "../../../components/atoms/Image/Image";
import Richtext from "../../../components/atoms/Richtext/Richtext";
import TranslationContext from "../../../contexts/TranslationContext";
import { ObjectFit } from "../../Page/types";
import "./CmsTeaser.less";
import { ContentTeaser } from "./types";

interface Props {
  content: ContentTeaser;
}

const CmsTeaser = (props: Props) => {
  const content = props.content;

  // Link
  const isLink = Boolean(content.cta && content.link?.url?.current);
  const classNames = ["cms-teaser"];
  if (isLink) {
    classNames.push("cms-teaser--link");
  }

  // Image Variant
  switch (content.variant) {
    default:
    case "image-left":
      classNames.push("cms-teaser--image-left");
      break;
    case "image-right":
      classNames.push("cms-teaser--image-right");
      break;
  }

  // Image Object Fif
  let objectFit: ObjectFit = "cover";
  switch (content.objectFit) {
    default:
    case "none":
    case "cover":
      objectFit = "cover";
      break;
    case "contain":
      objectFit = "contain";
      break;
  }

  const children = (
    <>
      <Image
        image={content.asset}
        className="cms-teaser__image"
        objectFit={objectFit}
      />
      <div className="cms-teaser__copy">
        <span className="cms-teaser__dateline">{content.dateline}</span>
        <h2 className="cms-teaser__headline">{content.headline}</h2>
        <Richtext blocks={content._rawText} className="cms-teaser__text" />
        {isLink && <div className="cms-teaser__link">{content.cta}</div>}
      </div>
    </>
  );

  return isLink ? (
    <div className={classNames.join(" ")}>
      <Link to={`/${content.link.url.current}`} className="cms-teaser__content">
        {children}
      </Link>
    </div>
  ) : (
    <div className={classNames.join(" ")}>
      <div className="cms-teaser__content">{children}</div>
    </div>
  );
};

export default CmsTeaser;
