import React from "react";
import "./RadioGroup.less";

interface RadioOption {
  value: string | number;
  label: string;
}

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  name: string;
  label: string;
  options: RadioOption[];
  value?: string | number;
  className?: string;
}

const RadioGroup = (props: Props) => {
  const { name, label, className, options, value, ...rest } = props;
  const classNames = ["radio-group"];
  if (className) {
    classNames.push(className)
  }

  return (
    <div className={classNames.join(" ")}>
      <div className="radio-group__label">{label}</div>
      {props.options.map((option) => (
        <label key={option.value} className="radio-group__option">
          <div
            className={
              value === option.value
                ? "radio-group__bullet radio-group__bullet--active"
                : "radio-group__bullet"
            }
          />
          <input
            {...rest}
            type="radio"
            name={props.name}
            value={option.value}
            className="radio-group__input"
          />
          {option.label}
        </label>
      ))}
    </div>
  );
};

export default RadioGroup;
