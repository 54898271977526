import React from "react";
import { URL } from "../../../templates/Page/types";
import CustomLink from "../CustomLink/CustomLink";
import "./CTA.less";

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  to: URL | string;
  children: React.ReactNode;
  title?: string;
  isExternal?: boolean;
  isOnGround?: boolean;
}

const CTA = (props: Props) => {
  const classNames = ["cta"];
  if (props.className) {
    classNames.push(props.className);
  }
  if (props.isOnGround) {
    if (props.isOnGround) {
      classNames.push("cta--on-ground");
    }
  }

  return (
    <CustomLink
      to={props.to}
      className={classNames.join(" ")}
      title={props.title}
      isExternal={props.isExternal}
    >
      {props.children}
    </CustomLink>
  );
};

export default CTA;
