import React from "react";
import "./Input.less";

interface Props {
  name: string;
  label: string;
  placeholder: string;
  error?: string;
  className?: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const Input = React.forwardRef(
  (props: Props, ref: React.ForwardedRef<HTMLInputElement>) => {
    const { className, label, error, ...rest } = props;

    const classNames = ["input"];
    if (props.className) {
      classNames.push(props.className);
    }
    if (props.error) {
      classNames.push("input--error");
    }

    return (
      <span className={classNames.join(" ")}>
        <label htmlFor={props.name} className="input__label">
          {props.label}
        </label>
        <input ref={ref} id={props.name} className="input__input" {...rest} />
        {error && <div className="input__error">{props.error}</div>}
      </span>
    );
  }
);

export default Input;
