import React from "react";
import Translate from "../../../components/atoms/Translate/Translate";
import { ContentPricing } from "./types";
import Richtext from "../../../components/atoms/Richtext/Richtext";
import "./CmsPricing.less";
import Toggle from "../../../components/atoms/Toggle/Toggle";

interface Props {
  content: ContentPricing;
}

type Currency = "eur" | "usd";

const CmsPricing = (props: Props) => {
  const content = props.content;
  const classNames = ["cms-pricing"];
  const [locale, setLocale] = React.useState("en-US");
  const [currency, setCurrency] = React.useState<Currency>("eur");
  const eur = {
    yearly: content.yearly.eur,
    monthly: content.monthly.eur,
  };
  const usd = {
    yearly: content.yearly.usd,
    monthly: content.monthly.usd,
  };

  const display = new Intl.NumberFormat(locale, {
    style: "currency",
    currency: currency.toUpperCase(),
  });
  const yearly = (currency === "eur" ? eur.yearly : usd.yearly) / 12;
  const monthly = currency === "eur" ? eur.monthly : usd.monthly;
  let discount;
  const discountInPercent = Math.floor(
    Math.abs((100 * yearly) / monthly) - 100
  );
  if (discountInPercent !== 0) {
    discount = `${discountInPercent}%`;
  }

  const onChangeCurrency = (value: string | boolean) => {
    if (typeof value !== "string") return;
    const newValue = value.toLowerCase();
    switch (newValue) {
      case "eur":
      case "usd":
        setCurrency(value as Currency);
        break;
      default:
        break;
    }
  };

  React.useEffect(() => {
    setLocale(navigator.language);
  }, []);

  return (
    <div className={classNames.join(" ")}>
      <div className="cms-pricing__currency">
        <Toggle
          labels={{ left: "EUR", right: "USD" }}
          onChange={onChangeCurrency}
        />
      </div>
      <div className="cms-pricing__tiers">
        <div className="cms-pricing__tier" />
        <div className="cms-pricing__tier cms-pricing__tier--yearly">
          {discount && <div className="cms-pricing__discount">{discount}</div>}
          <div className="cms-pricing__price">
            {display.format(yearly)}
            <div className="cms-pricing__per-month">
              <Translate const="pricing.perMonth" />
            </div>
          </div>
          <div className="cms-pricing__billing">
            <Translate const="pricing.yearly" />
          </div>
        </div>
        <div className="cms-pricing__tier cms-pricing__tier--monthly">
          <div className="cms-pricing__price">
            {display.format(monthly)}
            <div className="cms-pricing__per-month">
              <Translate const="pricing.perMonth" />
            </div>
          </div>
          <div className="cms-pricing__billing">
            <Translate const="pricing.monthly" />
          </div>
        </div>
      </div>
      <div className="cms-pricing__legal">
        <Richtext blocks={content._rawLegal} />
      </div>
    </div>
  );
};

export default CmsPricing;
