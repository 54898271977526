import React from "react";
import CustomLink from "../../../components/atoms/CustomLink/CustomLink";
import ObfuscatedLink from "../../../components/atoms/ObfuscatedLink/ObfuscatedLink";
import Richtext from "../../../components/atoms/Richtext/Richtext";
import Translate from "../../../components/atoms/Translate/Translate";
import { ContentContact } from "./types";
import "./CmsContact.less";

interface Props {
  content: ContentContact;
}

const CmsContact = (props: Props) => {
  const content = props.content;
  return (
    <div className="cms-contact">
      <div className="cms-contact__block">
        <div className="cms-contact__title">{content.title}</div>
        <div className="cms-contact__text">
          <Richtext blocks={content._rawText} />
        </div>
      </div>
      <div className="cms-contact__block cms-contact__block--main">
        <div className="cms-contact__sub-block">
          <div className="cms-contact__title">
            <Translate const="contact.details" />
          </div>
          <ObfuscatedLink email={content.email} />
        </div>
        {content.twitter && (
          <div className="cms-contact__sub-block">
            <div className="cms-contact__title">
              <Translate const="contact.twitter" />
            </div>
            <CustomLink
              isExternal={true}
              to={`https://twitter.com/@${content.twitter}`}
            >
              @{content.twitter}
            </CustomLink>
          </div>
        )}
      </div>
    </div>
  );
};

export default CmsContact;
