import { yupResolver } from "@hookform/resolvers/yup";
// @ts-ignore
import addToMailchimp from "gatsby-plugin-mailchimp";
import React from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import Button from "../../../components/atoms/Button/Button";
import Input from "../../../components/atoms/Input/Input";
import Loading from "../../../components/atoms/Loading/Loading";
import Richtext from "../../../components/atoms/Richtext/Richtext";
import TranslationContext from "../../../contexts/TranslationContext";
import "./CmsNewsletter.less";
import { ContentNewsletter } from "./types";

interface Props {
  content: ContentNewsletter;
}

type NewsletterFormData = {
  name: string;
  email: string;
};

const CmsNewsletter = (props: Props) => {
  const translations = React.useContext(TranslationContext);
  const { t } = translations;
  const content = props.content;
  const schema = React.useMemo(
    () =>
      yup.object().shape({
        name: yup.string().required(t("error.name.required")),
        email: yup
          .string()
          .email(t("error.email.invalid"))
          .required(t("error.email.required")),
      }),
    [translations]
  );
  const [formState, setFormState] = React.useState("form");
  const { register, errors, handleSubmit } = useForm<NewsletterFormData>({
    mode: "onBlur",
    resolver: yupResolver(schema),
  });

  const onSubmit = (data: NewsletterFormData) => {
    setFormState("loading");

    const listFields = {
      FNAME: data.name,
      PATHNAME: window.location.pathname,
    };
    addToMailchimp(data.email, listFields)
      .then(() => {
        setFormState("done");
      })
      .catch(() => {});
  };

  let renderedForm = null;
  switch (formState) {
    default:
    case "form":
    case "loading":
      const isLoading = formState === "loading";
      renderedForm = (
        <>
          <h2 className="cms-newsletter__headline">{content.title}</h2>
          <p>{content.text}</p>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Input
              ref={register}
              label={t("field.name")}
              name="name"
              error={errors.name && errors.name.message}
              placeholder={t("field.name.placeholder")}
            />
            <Input
              ref={register}
              label={t("field.email")}
              name="email"
              error={errors.email && errors.email.message}
              placeholder={t("field.email.placeholder")}
            />
            <div className="cms-newsletter__action">
              <Button
                type="submit"
                isOnGround={true}
                disabled={isLoading}
                className="cms-newsletter__button"
              >
                {content.cta}
              </Button>
              {isLoading && <Loading />}
            </div>
          </form>
          <div className="cms-newsletter__legal">
            <Richtext blocks={content._rawLegal} />
          </div>
        </>
      );
      break;
    case "done":
      renderedForm = (
        <>
          <h2 className="cms-newsletter__headline">
            {t("newsletter.success.title")}
          </h2>
          <div>{t("newsletter.success.text")}</div>
        </>
      );
      break;
  }

  return <div className="cms-newsletter">{renderedForm}</div>;
};

export default CmsNewsletter;
