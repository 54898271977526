import React from "react";
import Image from "../../../components/atoms/Image/Image";
import PagePadding from "../../../components/templates/PagePadding/PagePadding";
import "./CmsTestimonial.less";
import { ContentTestimonial } from "./types";

interface Props {
  content: ContentTestimonial;
}

const Testimonial = (props: Props) => {
  const content = props.content;
  return (
    <PagePadding className="cms-testimonial">
      <div className="cms-testimonial__testimonial">
        <span className="cms-testimonial__quote">»</span>
        {content.testimonial}
        <span className="cms-testimonial__quote">«</span>
      </div>
      <div className="cms-testimonial__origin">
        <Image
          image={content.image}
          title={`${content.person} — ${content.title}`}
          sizes={[135]}
          className="cms-testimonial__image"
        />
        <div className="cms-testimonial__person">
          {content.person}
          <div className="cms-testimonial__title">{content.title}</div>
        </div>
      </div>
    </PagePadding>
  );
};

export default Testimonial;
