import React from "react";
import "./CmsFAQSection.less";
import { FAQSection } from "./types";
import CmsFAQQuestion from "./CmsFAQQuestion";

interface Props {
  section: FAQSection;
  className?: string;
}

const CmsFAQSection = (props: Props) => {
  const classNames = ["faq-section"];
  if (props.className) {
    classNames.push(props.className);
  }

  return (
    <div className={classNames.join(" ")}>
      <div className="faq-section__head">
        <h3 className="faq-section__headline">{props.section.title}</h3>
        {props.section.description && (
          <span className="faq-section__description">
            {props.section.description}
          </span>
        )}
      </div>
      <ul className="faq-section__questions">
        {props.section.questions.map((question, i) => (
          <CmsFAQQuestion
            key={i}
            question={question.question}
            answer={question._rawAnswer}
          />
        ))}
      </ul>
    </div>
  );
};

export default CmsFAQSection;
