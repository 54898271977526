import React from "react";
import TranslationContext from "../../../contexts/TranslationContext";

interface Props {
  const: string;
}

const Translate = (props: Props) => {
  const translations = React.useContext(TranslationContext);
  const { t } = translations;

  return <>{t(props.const)}</>;
};

export default Translate;
