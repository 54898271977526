import React from "react";
import CTA from "../../../components/atoms/CTA/CTA";
import PagePadding from "../../../components/templates/PagePadding/PagePadding";
import "./CmsCallToAction.less";
import { ContentCallToAction } from "./types";

interface Props {
  content: ContentCallToAction;
}

const CmsCallToAction = (props: Props) => {
  const content = props.content;

  let cta = null;
  if (content.page && content.page.url) {
    cta = (
      <CTA to={content.page.url.current} title={content.page.url.current}>
        {content.cta}
      </CTA>
    );
  }
  if (content.isExternal && content.href) {
    cta = (
      <CTA to={content.href} isExternal={true}>
        {content.cta}
      </CTA>
    );
  }

  return <PagePadding className="cms-cta">{cta}</PagePadding>;
};

export default CmsCallToAction;
