import React from "react";
import PagePadding from "../../../components/templates/PagePadding/PagePadding";
import "./CmsHeadline.less";
import { ContentHeadline } from "./types";

interface Props {
  content: ContentHeadline;
}

const CmsHeadline = (props: Props) => {
  const content = props.content;
  return (
    <PagePadding className="cms-headline">
      {content.dateline && (
        <span className="cms-headline__dateline">{content.dateline}</span>
      )}
      <h1 className="cms-headline__headline">{content.headline}</h1>
      {content.subheadline && (
        <span className="cms-headline__subheadline">{content.subheadline}</span>
      )}
    </PagePadding>
  );
};

export default CmsHeadline;
