import React from "react";
import Button from "../../../../../components/atoms/Button/Button";
import Input from "../../../../../components/atoms/Input/Input";
import RadioGroup from "../../../../../components/atoms/RadioGroup/RadioGroup";
import Translate from "../../../../../components/atoms/Translate/Translate";
import PagePadding from "../../../../../components/templates/PagePadding/PagePadding";
import TranslationContext from "../../../../../contexts/TranslationContext";
import "./AutomationUrlEncoder.less";

const ACTIONS = {
  toggle: "url-encoder.action.toggle",
  start: "url-encoder.action.start",
  stop: "url-encoder.action.stop",
};

const ToolAutomationUrlEncoder = () => {
  const translations = React.useContext(TranslationContext);
  const { t } = translations;
  const [action, setAction] = React.useState<keyof typeof ACTIONS>("toggle");
  const [project, setProject] = React.useState("");
  const url = `proof://${
    project && `projects/${encodeURIComponent(project)}/`
  }${action}`;

  const onChangeAction = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAction(event.target.value as keyof typeof ACTIONS);
  };

  const onChangeProjectName = (event: React.ChangeEvent<HTMLInputElement>) => {
    setProject(event.target.value);
  };

  const onTest = () => {
    window.location.href = url;
  };

  const onCopyToClipboard = () => {
    navigator.clipboard.writeText(url);
  };

  return (
    <PagePadding>
      <div className="automation-url-encoder">
        <div className="automation-url-encoder__head">
          <h2 className="automation-url-encoder__headline">
            <Translate const="url-encoder.headline" />
          </h2>
          <div className="automation-url-encoder__description">
            <Translate const="url-encoder.description" />
          </div>
        </div>
        <div className="automation-url-encoder__content">
          <div className="automation-url-encoder__form">
            <RadioGroup
              name="action"
              label={t("url-encoder.actions")}
              options={Object.keys(ACTIONS).map((option) => ({
                value: option,
                label: t(ACTIONS[option as keyof typeof ACTIONS]),
              }))}
              value={action}
              onChange={onChangeAction}
              className="automation-url-encoder__field"
            />
            <Input
              name="project"
              label={t("url-encoder.project.label")}
              placeholder={t("url-encoder.project.placeholder")}
              onChange={onChangeProjectName}
              className="automation-url-encoder__field"
            />
          </div>
          <div className="automation-url-encoder__result">
            <div className="automation-url-encoder__result-label">
              <Translate const="url-encoder.result" />
            </div>
            <code className="automation-url-encoder__result-url">{url}</code>
            <Button
              size="small"
              isOnGround={true}
              onClick={onTest}
              className="automation-url-encoder__result-btn"
            >
              <Translate const="url-encoder.btn.test" />
            </Button>
            <Button
              size="small"
              isOnGround={true}
              onClick={onCopyToClipboard}
              className="automation-url-encoder__result-btn"
            >
              <Translate const="url-encoder.btn.copy" />
            </Button>
          </div>
        </div>
      </div>
    </PagePadding>
  );
};

export default ToolAutomationUrlEncoder;
