import React from "react";
import Richtext from "../../../components/atoms/Richtext/Richtext";
import PagePadding from "../../../components/templates/PagePadding/PagePadding";
import "./CmsRichtext.less";
import { ContentRichtext } from "./types";

interface Props {
  content: ContentRichtext;
}

const Text = (props: Props) => {
  const richtext = props.content;

  return (
    <PagePadding className="cms-richtext">
      <Richtext blocks={richtext._rawRichtext} />
    </PagePadding>
  );
};

export default Text;
