import React from "react";
import Image from "../../../components/atoms/Image/Image";
import Richtext from "../../../components/atoms/Richtext/Richtext";
import { ContentFeature } from "./types";
import "./CmsFeature.less";

interface Props {
  content: ContentFeature;
}

const CmsFeature = (props: Props) => {
  const content = props.content;
  const classNames = ["cms-feature"];

  // Image Variant
  switch (content.variant) {
    default:
    case "asset-left":
      classNames.push("cms-feature--asset-left");
      break;
    case "asset-right":
      classNames.push("cms-feature--asset-right");
      break;
  }

  return (
    <div className={classNames.join(" ")}>
      <div className="cms-feature__content">
        <Image
          image={content.asset}
          className="cms-feature__asset"
          objectFit="contain"
        />
        <div className="cms-feature__copy">
          <span className="cms-feature__dateline">{content.dateline}</span>
          <h2 className="cms-feature__headline">{content.headline}</h2>
          <Richtext blocks={content._rawText} className="cms-feature__text" />
        </div>
      </div>
    </div>
  );
};

export default CmsFeature;
