import React from "react";
import CustomLink from "../../../components/atoms/CustomLink/CustomLink";
import ObfuscatedLink from "../../../components/atoms/ObfuscatedLink/ObfuscatedLink";
import Translate from "../../../components/atoms/Translate/Translate";
import PagePadding from "../../../components/templates/PagePadding/PagePadding";
import "./CmsContactLegal.less";
import { ContentContactLegal } from "./types";

interface Props {
  content: ContentContactLegal;
}

const CmsContactLegal = (props: Props) => {
  const content = props.content;
  return (
    <PagePadding>
      <div className="cms-contact-legal">
        <div className="cms-contact-legal__block">
          <div className="cms-contact-legal__title">
            <Translate const="contact.address" />
          </div>
          <strong>{content.company}</strong>
          <br />
          {content.address}
          <br />
          {content.zip} {content.city}
          <br />
          {content.country}
          <br />
        </div>
        <div className="cms-contact-legal__block">
          <div className="cms-contact-legal__sub-block">
            <div className="cms-contact-legal__title">
              <Translate const="contact.details" />
            </div>
            <ObfuscatedLink email={content.email} />
          </div>
          {content.twitter && (
            <div className="cms-contact-legal__sub-block">
              <div className="cms-contact-legal__title">
                <Translate const="contact.twitter" />
              </div>
              <CustomLink
                isExternal={true}
                to={`https://twitter.com/@${content.twitter}`}
              >
                @{content.twitter}
              </CustomLink>
            </div>
          )}
        </div>
        <div className="cms-contact-legal__block">
          <div className="cms-contact-legal__sub-block">
            <div className="cms-contact-legal__title">
              <Translate const="contact.owner" />
            </div>
            {content.person}
          </div>
          <div className="cms-contact-legal__sub-block">
            <div className="cms-contact-legal__title">
              <Translate const="contact.vatId" />
            </div>
            {content.vatId}
          </div>
        </div>
      </div>
    </PagePadding>
  );
};

export default CmsContactLegal;
