import { graphql } from "gatsby";
import React from "react";
import SEO from "../../components/global/SEO/SEO";
import Layout from "../../components/global/Layout/Layout";
import Section from "../../components/templates/Section/Section";
import { PageContent, PageData, SiteTranslations, SiteSettings } from "./types";
import CmsHeadline from "../cms/CmsHeadline/CmsHeadline";
import CmsRichtext from "../cms/CmsRichtext/CmsRichtext";
import CmsTestimonial from "../cms/CmsTestimonial/CmsTestimonial";
import CmsPricing from "../cms/CmsPricing/CmsPricing";
import CmsFeature from "../cms/CmsFeature/CmsFeature";
import CmsContact from "../cms/CmsContact/CmsContact";
import CmsContactLegal from "../cms/CmsContactLegal/CmsContactLegal";
import CmsNewsletter from "../cms/CmsNewsletter/CmsNewsletter";
import CmsCallToAction from "../cms/CmsCallToAction/CmsCallToAction";
import { TranslationProvider } from "../../contexts/TranslationContext";
import { formatPageData } from "../../components/global/Layout/utils";
import CmsTeaser from "../cms/CmsTeaser/CmsTeaser";
import CmsStage from "../cms/CmsStage/CmsStage";
import CmsFAQ from "../cms/CmsFAQ/CmsFAQ";
import CmsTool from "../cms/CmsTool/CmsTool";

interface Props {
  children: React.ReactNode;
  data: {
    sanitySetting: SiteSettings;
    allSanityTranslation: SiteTranslations;
    sanityPage: PageData;
  };
  uri: string;
  pageContext: PageContent;
}

const Page = (props: Props) => {
  const site = props.data.sanitySetting;
  const page = formatPageData(site, props.data.sanityPage);

  const renderContent = (content: PageContent) => {
    switch (content._type) {
      case "stage":
        return (
          <Section
            key={content._key}
            className="page__section"
          >
            <CmsStage content={content} />
          </Section>
        );
      case "headline":
        return (
          <Section
            key={content._key}
            className="page__section"
          >
            <CmsHeadline content={content} />
          </Section>
        );
      case "teaser":
        return (
          <Section
            key={content._key}
            className="page__section"
          >
            <CmsTeaser content={content} />
          </Section>
        );
      case "pricing":
        return (
          <Section
            key={content._key}
            className="page__section"
          >
            <CmsPricing content={content} />
          </Section>
        );
      case "richtext":
        return (
          <Section key={content._key} className="page__section">
            <CmsRichtext content={content} />
          </Section>
        );
      case "callToAction":
        return (
          <Section
            key={content._key}
            className="page__section"
          >
            <CmsCallToAction content={content} />
          </Section>
        );
      case "testimonial":
        return (
          <Section
            key={content._key}
            className="page__section"
          >
            <CmsTestimonial content={content} />
          </Section>
        );
      case "feature":
        return (
          <Section
            key={content._key}
            className="page__section"
          >
            <CmsFeature content={content} />
          </Section>
        );
      case "newsletter":
        return (
          <Section
            key={content._key}
            className="page__section"
          >
            <CmsNewsletter content={content} />
          </Section>
        );
      case "contact":
        return (
          <Section key={content._key} className="page__section">
            <CmsContact content={content} />
          </Section>
        );
      case "contactLegal":
        return (
          <Section key={content._key} className="page__section">
            <CmsContactLegal content={content} />
          </Section>
        );
      case "faq":
        return (
          <Section key={content._key} className="page__section">
            <CmsFAQ content={content} />
          </Section>
        );
      case "tool":
        return (
          <Section key={content._key} className="page__section">
            <CmsTool content={content} />
          </Section>
        );
      default:
        return null;
    }
  };

  return (
    <TranslationProvider
      locale={page.locale}
      translations={props.data.allSanityTranslation}
    >
      <Layout header={page.header} footer={page.footer}>
        <SEO
          locale={page.locale}
          title={page.title}
          titleSuffix={site.titleSuffix}
          description={page.description}
          favicon={site.favicon.asset.url}
          image={page.ogImage}
        />
        {page.content.map((x) => renderContent(x))}
      </Layout>
    </TranslationProvider>
  );
};

export const query = graphql`
  query($id: String!) {
    ...settings
    ...translations
    ...pageById
  }
`;

export default Page;
